@import '../../colors';
@import '../../responsive';

.title {
  display: block;
  margin-top: 4rem;
  font-size: 1.6rem;
  color: colors(black);

  @include mq() {
    margin-top: 2.4rem;
    font-size: 1.4rem;
  }
}

.wrapper {
  width: 100%;
  min-height: 580px;
  margin-top: 4rem;
  overflow: initial;
  overflow-x: visible;
  background-color: colors(white, primary);

  @include mq() {
    margin-top: 2.4rem;
  }
}
