@import '../colors';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 12rem;
}

.loading {
  width: 6.4rem;
  height: 6.4rem;
  margin: auto;
}

.title {
  font-size: 1.4rem;
  font-weight: 700;
  color: colors(black);
}
