@import '../../colors';
@import '../../responsive';

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 24rem;
  overflow: hidden;
  letter-spacing: 0.1em;
  background-color: colors(theme);

  @include mq() {
    width: 0;
    transition: width 0.2s ease;
  }
}

.open {
  @include mq() {
    width: 24rem;
  }
}

.navigation {
  height: calc(100vh - 6.4rem);
  overflow: auto;
}

.navigation::-webkit-scrollbar {
  display: none;
}
