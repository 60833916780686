@import '../../colors';

.linkButton {
  display: inline-block;
  height: 2.4rem;
  padding: 0 2.4rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.4rem;
  color: colors(white);
  text-decoration: none;
  cursor: pointer;
  background-color: colors(theme, secondary);
  border: 0;
  border-radius: 0.4rem;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 1rem black(0.2);
  }
}
