@import '../../colors';

.container {
  width: 100%;
  margin-top: 1.6rem;

  span {
    display: block;
    width: 100%;
    font-size: 1rem;
    color: colors(theme, tertiary);
    text-overflow: ellipsis;
  }

  h4 {
    @extend span;

    font-size: 1.8rem;
    color: colors(black);
    overflow-wrap: break-word;
  }
}
