.queryContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 93.6rem;
}

.queryButton {
  width: 12rem;
  height: 4.8rem;
  line-height: 4.8rem;
}
