@import '../../colors';
@import '../../responsive';

.container {
  width: 100%;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;

  span {
    font-size: 1.4rem;
  }
}

.delete {
  background-color: colors(accent, error);
}

.table {
  margin-top: 3.2rem;
}
