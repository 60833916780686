@import '../../colors';

.machineCreateButton {
  width: auto;
  height: 3.2rem;
  line-height: 3.2rem;

  span {
    font-size: 1.4rem;
  }
}

.machineEditButton {
  width: 100%;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;
  background-color: colors(accent, info);

  span {
    font-size: 1.4rem;
  }
}

.editUpdateStatusButton {
  width: 16.5rem;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;
  background-color: colors(accent, info);

  span {
    font-size: 1.4rem;
  }
}
