@import '../../colors';

.container {
  display: block;
  width: 100%;
  height: 4.4rem;
  margin-top: 1.6rem;
  line-height: 4.4rem;
  text-decoration-line: none;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }

  span {
    display: block;
    font-size: 1.4rem;
    color: colors(black);
    text-align: right;

    &::after {
      display: inline-block;
      width: 0.5rem;
      height: 0.8rem;
      margin-left: 0.8rem;
      content: '';
      background: center center / contain no-repeat;
      background-image: url('../../../assets/images/icon-chevron.png');
    }
  }
}
