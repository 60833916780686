@import '../colors';
@import '../responsive';

.container {
  position: relative;
  width: 100%;
  max-width: 32.7rem;
  height: 5.6rem;
  padding: 0 1.6rem;
  margin-top: 4rem;
  overflow: hidden;
  line-height: 5.6rem;
  cursor: pointer;
  background-color: colors(theme, secondary);
  border: 0;
  border-radius: 0.4rem;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 1rem black(0.2);
  }

  &:disabled {
    color: colors(theme, tertiary);
    background-color: colors(gray, buttonDisabled);
  }

  span {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    color: colors(white);
    text-align: center;
  }

  @include mq() {
    width: 100%;
    margin-top: 2.4rem;
  }
}

.grayContainer {
  background-color: colors(gray);

  span {
    color: colors(black);
  }
}

.loadingWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: colors(theme, secondary);
}

.grayLoadingWrapper {
  background-color: colors(gray);
}

.visible {
  visibility: visible;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 2.4rem;
  height: 2.4rem;
  margin: auto;
}
