@import '../../colors';
@import '../../responsive';

.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.4rem;
  margin-left: -4rem;

  @include mq() {
    margin-top: 0;
    margin-left: 0;
  }
}

.image {
  width: 96rem;
  margin: 2.4rem 0 0 0;
}

.itemContainer {
  display: inline-block;
  width: 32rem;
  padding: 2.4rem 2.4rem 2.4rem 2.4rem;
  margin: 0 0 4rem 4rem;
  background-color: colors(white);
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem black(0.1);

  @include mq() {
    width: 100%;
    margin: 2.4rem 0 0 0;
  }
}
