@import '../colors';

.form {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;

  &:first-child {
    margin-top: 0.8rem;
  }
}

.label {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  color: colors(black);
}

.select {
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  margin-top: 1.2rem;
  font-size: 1.6rem;
  color: colors(black);
  background-color: colors(gray);
  border: 0;
  border-radius: 0.4rem;

  &:disabled {
    color: colors(theme, tertiary);
    background-color: colors(gray, secondary);
  }
}
