@import '../../colors';
@import '../../responsive';

.container {
  display: flex;
  flex-wrap: wrap;

  @include mq() {
    margin-left: 0;
  }
}

.title {
  display: block;
  margin-top: 4rem;
  font-size: 1.6rem;
  color: colors(black);

  @include mq() {
    margin-top: 2.4rem;
    font-size: 1.4rem;
  }
}
