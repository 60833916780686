@import '../../colors';
@import '../../responsive';

.container {
  width: 100%;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;
  background-color: colors(accent, error);

  span {
    font-size: 1.4rem;
  }
}