@import '../../colors';
@import '../../responsive';

.container {
  margin-top: 4rem;

  @include mq() {
    margin-top: 2.4rem;
  }
}

.box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.wrapper {
  position: relative;
  display: flex;
  width: 27.2rem;
  margin-top: 1.2rem;

  &::before {
    position: absolute;
    top: 0;
    right: 5.6rem;
    z-index: 2;
    display: block;
    display: inline-block;
    width: 0.8rem;
    height: 4.8rem;
    content: '';
    background: center center / contain no-repeat;
    background-image: url('../../../assets/images/icon-triangle.png');
  }
}

input,
select {
  width: 23.2rem;
  height: 4.8rem;
  padding: 0 4rem 0 1.6rem;
  font-size: 1.6rem;
  color: colors(black);
  text-overflow: ellipsis;
  background-color: colors(white);
  border: 0;
  border-radius: 0.4rem;
  box-shadow: 0 0 1rem black(0.1);
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: none;
  }

  &::placeholder {
    color: colors(placeholder);
  }
}

.title {
  font-size: 1.4rem;
  font-weight: 700;
  color: colors(black);
}

.fromPickerWrapper {
  @extend .wrapper;

  &::after {
    display: inline-block;
    width: 4rem;
    height: 4.8rem;
    font-size: 2.4rem;
    line-height: 4.8rem;
    color: colors(black);
    text-align: center;
    content: '〜';
  }
}

.toPickerWrapper {
  @extend .wrapper;

  &::after {
    display: inline-block;
    width: 4rem;
    height: 4.8rem;
    padding-left: 1rem;
    font-size: 2.4rem;
    line-height: 4.8rem;
    color: colors(black);
    text-align: center;
    content: '';
  }
}

.statusWrapper {

  &::after {
    display: inline-block;
    width: 4rem;
    height: 4.8rem;
    padding-left: 1rem;
    font-size: 2.4rem;
    line-height: 4.8rem;
    color: colors(black);
    text-align: center;
    content: '';
  }
}
