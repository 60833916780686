@import '../colors';
@import '../responsive';

.wrapper {
  width: 100%;
  margin-top: 4rem;
  overflow: initial;
  overflow-x: visible;

  @include mq() {
    margin-top: 2.4rem;
  }
}

.title {
  display: block;
  margin-top: 4rem;
  font-size: 1.8rem;
  color: colors(black);

  @include mq() {
    margin-top: 2.4rem;
    font-size: 1.6rem;
  }
}

.description {
  font-size: 1.4rem;
}

.zebra {

  .row,
  .rowStatic {

    &:nth-child( odd ) {
      background-color: colors(white, secondary);
    }

    &:first-child {
      background-color: white;
    }
  }
}

.stocker {
  margin-top: 2.4rem;

  @include mq() {
    margin-top: 1.6rem;
  }
}

.container {
  width: 100%;
  background-color: colors(white);
}

.row {
  height: 4rem;
  line-height: 4rem;
  border-bottom: 0.1rem solid colors(gray, tertiary);

  &:first-child {
    position: sticky;
    top: 6.4rem;
    z-index: 2;

    @include mq() {
      top: 5.6rem;
    }
  }

  &:last-child {
    border: 0;
  }
}

.rowStatic {
  height: 4rem;
  line-height: 4rem;
  border-bottom: 0.1rem solid colors(gray, tertiary);

  &:last-child {
    border: 0;
  }
}

.cell {
  padding: 0 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: colors(black);
  text-align: left;
  white-space: nowrap;
  border-right: 0.1rem solid colors(gray, tertiary);

  &:last-child {
    border: 0;
  }
}

.textCell {
  @extend .cell;

  padding: 1.2rem;
  line-height: 2.4rem;
  word-wrap: break-word;
  white-space: normal;
}

.longTextCell {
  @extend .textCell;

  min-width: 20rem;
}

.buttonCell {
  @extend .cell;

  text-align: center;

  button {
    width: auto;
    height: 2.4rem;
    padding: 0 2.4rem;
    margin: 0;
    line-height: 2.4rem;

    span {
      font-size: 1.4rem;
    }
  }
}

.inputRowStatic {
  @extend .rowStatic;

  line-height: 2.4rem;
}

.inputCell {
  @extend .cell;

  padding: 0.8rem;
}

.itemInputCell {
  @extend .inputCell;

  width: 100%;
}

#isUnderEmergency {
  background-color: colors(accent, error);
}
