html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  padding: 0;
  margin: 0;
  border: 0;
}

html,
body {
  height: 100%;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

html {
  font-family: Noto Sans JP, sans-serif;
  font-size: 62.5%;

  &.wf-active {
    font-weight: 700;
  }
}

h2 {
  letter-spacing: 0.1em;
}

h3 {
  letter-spacing: 0.1em;
}

* {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-appearance: none;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

*::before,
*::after {
  box-sizing: inherit;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

ol,
ul {
  list-style: none;
}

img,
video {
  max-width: 100%;
}

img {
  user-select: none;
  border-style: none;
  -webkit-user-drag: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: '';
}

[ hidden ] {
  display: none !important;
}

[ disabled ] {
  cursor: not-allowed;
}

input,
textarea,
select,
option,
button,
div,
span {

  &:focus {
    outline: 0;
  }
}
