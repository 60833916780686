@import '../../colors';
@import '../../responsive';

.title {
  display: block;
  margin-top: 4rem;
  font-size: 1.6rem;
  color: colors(black);

  @include mq() {
    margin-top: 2.4rem;
    font-size: 1.4rem;
  }
}

.emergencyButton {
  width: auto;
  height: 3.2rem;
  line-height: 3.2rem;
  background-color: colors(accent, error);

  span {
    font-size: 1.4rem;
  }
}

.openAllLockerBoxesButton {
  width: auto;
  height: 3.2rem;
  line-height: 3.2rem;
  background-color: colors(accent, info);

  span {
    font-size: 1.4rem;
  }
}

.timeSlotButton {
  width: auto;
  height: 3.2rem;
  margin-top: 2.4rem;
  margin-right: 2.4rem;
  line-height: 3.2rem;
  background-color: colors(accent, info);

  span {
    font-size: 1.4rem;
  }
}

.description {
  padding-top: 0.8rem;
  font-size: 1.4rem;
}

.selectedDateWrapper {
  display: flex;
  align-items: center;
  padding-top: 2.4rem;
}

.selectedDateButton {
  width: auto;
  height: 3.2rem;
  margin-top: 0;
  margin-right: 2.4rem;
  line-height: 3.2rem;
  background-color: colors(accent, info);

  span {
    font-size: 1.4rem;
  }
}

.stationDateForm {
  margin-right: 2.4rem;
}

.stationEditButton {
  width: 12rem;
  height: 3.2rem;
  margin-right: 1.6rem;
  line-height: 3.2rem;

  span {
    font-size: 1.4rem;
  }
}
