@import '../../colors';
@import '../../responsive';

.title {
  font-size: 1.4rem;
  font-weight: 700;
  color: colors(black);
}

.input {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: colors(black);
  resize: none;
  background-color: colors(gray, light);
  border: 0;
  border-radius: 0.1rem;
  box-shadow: 0 0 0.5rem black(0.05) inset;

  &:disabled {
    color: colors(theme, tertiary);
    background-color: colors(gray, secondary);
  }
}

.textInput {
  @extend .input;

  height: 20rem;
}

.selectInput {
  @extend .input;

  &::after {
    display: inline-block;
    width: 4rem;
    height: 4.8rem;
    font-size: 2.4rem;
    line-height: 4.8rem;
    color: colors(black);
    text-align: center;
    content: '〜';
  }
}

.addButton {
  display: inline-block;
  height: 2.4rem;
  margin: 0;
  line-height: 1.6rem;

  span {
    font-size: 1.4rem;
  }
}

.deleteButton {
  @extend .addButton;

  background-color: colors(accent, error);
}
