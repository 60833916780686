@import '../../colors';
@import '../../responsive';

.container {
  width: 100%;
  margin: 0;
  line-height: 2.4rem;

  span {
    font-size: 1.4rem;
  }
}

.table {
  margin-top: 3.2rem;
}

.icon {
  display: block;
  width: 1rem;
  height: 1rem;
  background: center center / cover no-repeat;
}

.cell {
  max-width: 40rem;
  height: 4rem;
  padding: 0.8rem 1.6rem;
  overflow: hidden;
  font-size: 1.4rem;
  font-weight: 500;
  color: colors(black);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: 0.1rem solid colors(gray, tertiary);

  &:last-child {
    border: 0;
  }
}

.expandedCell {
  @extend .cell;

  height: auto;
  overflow: visible;
  overflow-wrap: break-word;
  white-space: normal;
}

.row {
  line-height: 2.4rem;
  border-bottom: 0.1rem solid colors(gray, tertiary);

  &:last-child {
    border: 0;
  }
}
