@import '../../colors';
@import '../../responsive';

.container {
  position: absolute;
  top: 5rem;
  right: 2.4rem;
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  background-color: colors(white);
  border-radius: 0.4rem;
  box-shadow: 0 0 1rem black(0.1);
  transition: height 0.2s ease;

  .item {
    pointer-events: none;
  }
}

.visible {
  height: 3.2rem;

  .item {
    pointer-events: all;
  }
}

.item {
  display: block;
  width: 14.4rem;
  height: 3.2rem;
  padding: 0.8rem;
  text-decoration-line: none;
  background: none;
  border: 0;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }

  span {
    display: block;
    font-size: 1.4rem;
    color: colors(black);
    text-align: left;
  }
}
