@import '../../colors';
@import '../../responsive';

.wrapper {
  display: inline-block;
  width: 100%;
  max-width: 106.4rem;
  margin-top: 2.4rem;
  overflow: initial;
  overflow-x: visible;
  border-radius: 0.8rem;
  -webkit-overflow-scrolling: touch;

  @include mq() {
    margin-top: 1.6rem;
  }
}

.container {
  width: 100%;
  border-collapse: collapse;
  background-color: colors(white);
  border-radius: 0.8rem;
}

.row {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  border-bottom: 0.1rem solid colors(gray, tertiary);

  &:last-child {
    border: 0;
  }
}

.cell {
  padding: 0 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: colors(black);
  text-align: left;
  white-space: nowrap;
  vertical-align: top;
  border-right: 0.1rem solid colors(gray, tertiary);

  &:first-child {
    width: 16rem;
  }

  &:last-child {
    border: 0;
  }
}

.wrap {
  white-space: normal;
}

.image {
  width: 32rem;
  margin: 1.6rem auto;
  vertical-align: middle;
}

.textCell {
  @extend .cell;

  padding: 0 1.6rem;
  word-wrap: break-word;
  white-space: normal;
}

.inputCell {
  @extend .cell;

  padding: 0.5rem 0.8rem;
}

.stationDateForm {
  width: 88rem;
  height: 3rem;
  background-color: #ecf0f1;
}
