@import '../colors';
@import '../responsive';

.container {
  padding: 4rem;

  @include mq() {
    padding: 2.4rem;
  }
}

.title {
  display: block;
  font-size: 2.4rem;
  color: colors(black);

  @include mq() {
    font-size: 1.6rem;
  }
}
