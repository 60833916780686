@import '../colors';
@import '../responsive';

.wrapper {
  position: relative;
  overflow: visible;
  transition: filter 0.2s ease;
}

.blur {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: blur(0.5rem);
}

.guestWrapper {
  position: inherit;
}

.recognizer {
  display: none;
  visibility: hidden;

  @include mq() {
    position: absolute;
    top: 5.6rem;
    right: 0;
    bottom: 0;
    left: 24rem;
    z-index: 999;
    cursor: pointer;
  }
}

.visible {
  display: block;
  visibility: visible;
}

.container {
  padding: 6.4rem 0 0 24rem;
  background-color: colors(gray);

  @include mq() {
    padding: 5.6rem 0 0 0;
    transition: transform 0.2s ease;
  }
}

.open {
  @include mq() {
    transform: translateX(24rem);
  }
}

.guest {
  padding: 0;
}
