@import '../../colors';
@import '../../responsive';

.container {
  width: 100%;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;

  span {
    font-size: 1.4rem;
  }
}

.row {
  height: 4rem;
  line-height: 4rem;
  border-bottom: 0.1rem solid colors(gray, tertiary);

  &:first-child {
    position: sticky;
    top: 6.4rem;
    z-index: 2;

    @include mq() {
      top: 5.6rem;
    }
  }

  &:last-child {
    border: 0;
  }
}

.hasTroubleRow {
  background-color: colors(accent, error);
}
