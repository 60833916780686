@import '../../colors';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3.2rem;
  line-height: 3.2rem;
}

.sign {
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: colors(accent, success);
  border-radius: 1rem;
}

.off {
  background-color: colors(accent, error);
}

.statement {
  display: block;
  margin-left: 0.8rem;
  font-size: 1.4rem;
  color: colors(black);
}
