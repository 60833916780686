.description {
  padding-top: 0.8rem;
  font-size: 1.4rem;
}

.timeSlotButton {
  width: auto;
  height: 3.2rem;
  margin-top: 2.4rem;
  margin-right: 2.4rem;
  line-height: 3.2rem;
  background-color: colors(accent, info);

  span {
    font-size: 1.4rem;
  }
}
