@import '../../colors';
@import '../../responsive';

.updateButton {
  width: 100%;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;

  span {
    font-size: 1.4rem;
  }
}

.deleteButton {
  width: 100%;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;
  background-color: colors(theme, quaternary);

  span {
    font-size: 1.4rem;
  }
}

.orderIcon,
.orderIcon span {
  box-sizing: border-box;
  display: inline-block;
}

.orderIcon {
  position: relative;
  width: 20px;
  height: 30px;
  appearance: none;
  cursor: pointer;
}

.orderIcon span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #d2d2d2;
  border-radius: 4px;
}

.orderIcon span:nth-of-type( 1 ) {
  top: 14px;
}

.orderIcon span:nth-of-type( 2 ) {
  top: 20px;
}

.orderIcon span:nth-of-type( 3 ) {
  bottom: 0;
}

.cell {
  width: 4.8rem;
  padding: 0 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: colors(black);
  text-align: left;
  white-space: nowrap;
  border-right: 0.1rem solid colors(gray, tertiary);

  &:last-child {
    border: 0;
  }
}

.buttonCell {
  width: 4.8rem;
  padding: 0 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: colors(black);
  text-align: left;
  white-space: nowrap;
  border-right: 0.1rem solid colors(gray, tertiary);

  &:last-child {
    border: 0;
  }
}

.imgCell {
  display: flex;
  align-items: center;
  width: 28rem;
  padding: 0.8rem 1.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: colors(black);
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  border-right: 0.1rem solid colors(gray, tertiary);

  &:last-child {
    border: 0;
  }
}

.row {
  height: 4rem;
  line-height: 4rem;
  border-bottom: 0.1rem solid colors(gray, tertiary);
}

.image {
  width: 24rem;
}
