@import '../../colors';
@import '../../responsive';

.container {
  display: inline-block;
  width: 64rem;
  padding: 2.4rem 2.4rem 0 2.4rem;
  margin: 4rem 0 0 4rem;
  background-color: colors(white);
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem black(0.1);

  @include mq() {
    width: 100%;
    margin: 2.4rem 0 0 0;
  }
}

.grayContainer {
  background-color: colors(gray, secondary);
}

.power {
  display: flex;
  justify-content: space-between;
  height: 3.2rem;
}

.run {
  width: auto;
  height: 3.2rem;
  margin: 0;
  line-height: 3.2rem;

  span {
    font-size: 1.4rem;
  }
}

.emergencyContainer {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.emergencyLabel {
  width: auto;
  font-weight: bold;

  span {
    color: colors(white);
    background: colors(accent, error);
  }
}

.runLoading {
  width: 1.6rem;
  height: 1.6rem;
}
