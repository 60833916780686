@import '../../colors';
@import '../../responsive';

.createPromotionCampaignButton {
  width: auto;
  height: 3.2rem;
  margin-bottom: 1.6rem;
  line-height: 3.2rem;

  span {
    font-size: 1.4rem;
  }
}

.table {
  margin-top: 1.6rem;
}

.accentButton {
  background-color: colors(accent, error);
}

.row {
  height: 4rem;
  line-height: 4rem;
  border-bottom: 0.1rem solid colors(gray, tertiary);

  &:first-child {
    position: sticky;
    top: 6.4rem;
    z-index: 2;

    @include mq() {
      top: 5.6rem;
    }
  }

  &:last-child {
    border: 0;
  }
}

.zebra {

  .row,
  .rowStatic {

    &:nth-child( odd ) {
      background-color: colors(white, secondary);
    }

    &:first-child {
      background-color: white;
    }
  }
}

.isExpireRow {
  background-color: colors(gray, secondary) !important;
}
