@import '../../colors';
@import '../../responsive';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
  transform: scale(0);
}

.overlay {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
  z-index: -1;
  content: '';
  background-color: black(0.25);
  filter: blur(0.5rem);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.wrapper {
  width: 32rem;
  max-height: 100%;
  overflow: visible;
  background-color: colors(white);
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem black(0.1);
  transition: transform 0.2s ease 0.2s;
  transform: scale(0);

  @include mq() {
    width: 100%;
  }
}

.isVisible {
  transform: scale(1);

  .overlay {
    opacity: 1;
  }

  .wrapper {
    transform: scale(1);
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  background-color: black(0.1);
  border-radius: 1.2rem;

  span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    background: center center / contain no-repeat;
    background-image: url('../../../assets/images/icon-cross.png');
  }
}

.main {
  width: 100%;
  max-height: calc(100vh - 10.4rem);
  padding: 0 2.4rem 2.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.decide {
  height: 4.8rem;
  margin-top: 2.4rem;
  line-height: 4.8rem;
}

.report {
  @extend .decide;

  background-color: colors(accent, error);

  div {
    background-color: colors(accent, error);
  }
}

.formSeparator {
  height: 2px;
  margin-top: 4rem;
  background: colors(gray);
}

.dateInput {
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  margin-top: 1.2rem;
  font-size: 1.6rem;
  color: colors(black);
  background-color: colors(gray);
  border: 0;
  border-radius: 0.4rem;

  &:disabled {
    color: colors(theme, tertiary);
    background-color: colors(gray, secondary);
  }
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;

  &:first-child {
    margin-top: 0.8rem;
  }
}

.label {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  color: colors(black);
}

.input {
  display: block;
  width: 100%;
  height: 3em;
  padding: 1rem;
  margin-top: 1.2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  color: colors(black);
  cursor: pointer;
  resize: none;
  background-color: colors(gray, light);
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem black(0.05) inset;

  &:disabled {
    color: colors(theme, tertiary);
    background-color: colors(gray, secondary);
  }
}

.textarea {
  @extend .input;

  height: 20rem;
  padding: 1rem;
  margin-top: 1.2rem;
  font-size: 1.5rem;
  background-color: colors(gray, light);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem black(0.05) inset;
}

.contentText {
  padding: 0 1rem;
  margin-top: 1.2rem;
  color: colors(black);
}

.toPickerWrapper {
  width: 27rem;
  margin: 2.4rem 0 1.2rem 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: #1c2833;
}

.manufacturedForm {
  width: 27rem;
  background-color: #ecf0f1;
}

.switchContainer {
  height: 2.4rem;
  margin: 0;
  background-color: colors(accent, info);
}

.text {
  font-size: 1.2rem;
}
