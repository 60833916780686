@import '../../responsive';

.promotionImageCreateButton {
  width: auto;
  height: 3.2rem;
  line-height: 3.2rem;
  background-color: colors(accent, error);

  span {
    font-size: 1.4rem;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.tableContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4rem;

  @include mq() {
    margin-left: 0;
  }
}
