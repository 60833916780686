@import '../../colors';
@import '../../responsive';

.button {
  width: 100%;
  height: 2.4rem;
  margin: 0;
  line-height: 2.4rem;

  span {
    font-size: 1.4rem;
  }
}

.accentButton {
  @extend .button;

  background-color: colors(accent, error);
}
