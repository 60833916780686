@import '../../colors';

.input {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.8rem;
  font-size: 1.4rem;
  line-height: 3rem;
  color: colors(black);
  resize: none;
  background-color: colors(gray, light);
  border: 0;
  border-radius: 0.1rem;
  box-shadow: 0 0 0.5rem black(0.05) inset;

  &:disabled {
    color: colors(theme, tertiary);
    background-color: colors(gray, secondary);
  }
}

.textInput {
  @extend .input;

  height: 20rem;
  padding: 0 0.8rem;
}

.selectInput {
  @extend .input;

  padding: 0 0.8rem;
}
