@import '../../colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-width: 24rem;
  height: 6.4rem;
  padding: 0 2.4rem;
  border-bottom: 0.1rem solid black(0.1);
}

.company {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  color: colors(white);
}

.service {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: white(0.5);
}
