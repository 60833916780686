@import '../colors';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  text-decoration-line: none;
  cursor: url('https://bit.ly/32ChNTE'), url('https://bit.ly/2Snavhw'), pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.title {
  display: block;
  font-size: calc(100vw / 3);
  font-weight: 700;
  color: rgba(0, 0, 0, 0);
  text-align: center;
  background-color: colors(black);
  background-image: url('../../assets/images/not-found.png');
  background-repeat: repeat;
  background-position: center center;
  -webkit-background-clip: text;
  background-clip: text;
  transition: background 0.2s ease;
}
