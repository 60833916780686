@import '../../colors';
@import '../../responsive';

.title {
  display: block;
  margin-top: 4rem;
  font-size: 1.6rem;
  color: colors(black);

  @include mq() {
    margin-top: 2.4rem;
    font-size: 1.4rem;
  }
}
