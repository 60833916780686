@import '../colors';
@import '../responsive';

.container {
  position: fixed;
  top: 2.4rem;
  right: 2.4rem;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 32rem;
  height: 7.2rem;
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem black(0.1);
  transition: transform 0.2s ease;
  transform: scale(0);

  @include mq() {
    right: 2.4rem;
    left: 2.4rem;
    width: calc(100% - 4.8rem);
  }
}

.visible {
  transform: scale(1);
}

.success {
  background-color: colors(accent, success);
}

.error {
  background-color: colors(accent, error);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  background-color: black(0.1);
  border: 0;
  border-radius: 1.2rem;
  appearance: none;
}

.close {
  width: 0.8rem;
  height: 0.8rem;
  background: center center / cover no-repeat;
  background-image: url('../../assets/images/icon-cross.png');
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 4rem);
  height: 100%;
}

.title {
  display: block;
  width: 100%;
  overflow: hidden;
  font-size: 1.6rem;
  font-weight: 700;
  color: colors(white);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.body {
  @extend .title;

  font-size: 1.4rem;
  font-weight: 500;
}
