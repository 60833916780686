@import '../../colors';

.queryContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 93.6rem;
}

.queryButton {
  width: 12rem;
  height: 4.8rem;
  line-height: 4.8rem;
}

.createGivePointsButton {
  width: auto;
  height: 3.2rem;
  line-height: 3.2rem;

  span {
    font-size: 1.4rem;
  }
}

.createExpirePointsButton {
  width: auto;
  height: 3.2rem;
  margin-left: 1.4em;
  line-height: 3.2rem;
  background-color: colors(accent, error);

  span {
    font-size: 1.4rem;
  }
}
