@import '../../colors';
@import '../../responsive';

.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 24rem;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.4rem;
  padding: 0 2.4rem;
  background-color: colors(white);
  box-shadow: 0 0.2rem 1rem black(0.1);

  @include mq() {
    left: 0;
    height: 5.6rem;
    transition: transform 0.2s ease;
  }
}

.visible {
  @include mq() {
    transform: translateX(24rem);
  }
}

.pc {
  width: 107px;
  height: 30px;
  background: url('../../../assets/images/logo.png') center center / cover;

  @include mq() {
    display: none;
    visibility: hidden;
  }
}

.mobile {
  display: none;
  visibility: hidden;

  @include mq() {
    position: relative;
    display: block;
    width: 1.6rem;
    height: 3.2rem;
    visibility: visible;
    background: none;
    border: 0;

    span {
      position: absolute;
      top: 0;
      bottom: 0;
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      margin: auto 0;
      transition: transform 0.2s ease 0.2s;

      &::after {
        position: absolute;
        top: 0.4rem;
        right: 0;
        left: 0;
        display: block;
        width: 1.6rem;
        height: 0.2rem;
        content: '';
        background-color: colors(black);
        border-radius: 0.1rem;
        transition: top 0.2s ease;
      }

      &:last-child {

        &::after {
          top: auto;
          bottom: 0.4rem;
          transition: bottom 0.2s ease;
        }
      }
    }
  }
}

.open {
  @include mq() {

    span {
      transform: rotate(45deg);

      &::after {
        top: 0.7rem;
      }

      &:last-child {
        transform: rotate(-45deg);

        &::after {
          bottom: 0.7rem;
        }
      }
    }
  }
}

.user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: none;
  border: 0;
  transition: opacity 0.1s ease;

  &:hover {
    opacity: 0.7;
  }

  span {
    display: block;
    max-width: 22.4rem;
    overflow: hidden;
    font-size: 1.4rem;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mq() {
      max-width: 11.2rem;
    }
  }

  &::after {
    display: block;
    width: 0.8rem;
    height: 0.4rem;
    margin-left: 0.8rem;
    content: '';
    background: center center / cover no-repeat;
    background-image: url('../../../assets/images/icon-triangle.png');
  }
}

.recognizer {
  display: none;
  visibility: hidden;
}

.visibleRecognizer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  cursor: pointer;
  visibility: visible;
}
