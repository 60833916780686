.container {
  margin-top: 4rem;
}

.select {
  padding: 10px;
  padding-right: 45px;
  padding-left: 35px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  appearance: none;
}

.label {
  position: relative;
  display: inline-block;
}

.arrowIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 15px;
  pointer-events: none;
  transform: translateY(-50%);
}

.cameraIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 20px;
  pointer-events: none;
  transform: translateY(-50%);
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}
