@import '../../colors';

.menuItemContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 24rem;
  height: 5.6rem;
  padding: 0 2.4rem;
  text-decoration-line: none;
  cursor: pointer;
  border-bottom: 0.1rem solid black(0.1);
  transition: background-color 0.1s linear;

  &:hover {
    background-color: white(0.2);
  }
}

.subMenuItemContainer {
  padding-left: 4.8rem;
}

.selected {
  background-color: white(0.2);
}

.icon {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  background: center center / cover no-repeat;
}

.subMenuIcon {
  background-size: 1.2rem auto;
}

.label {
  display: block;
  margin-left: 1.6rem;
  font-size: 1.4rem;
  color: colors(white);
}
