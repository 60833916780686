@import '../../colors';
@import '../../responsive';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32rem;
  padding: 2.4rem;
  background-color: colors(white);
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem black(0.1);

  @include mq() {
    width: 100%;
  }
}

.title {
  display: block;
  font-size: 2.4rem;
  color: colors(black);
}

.description {
  display: block;
  margin-top: 0.8rem;
  font-size: 1.4rem;
  color: colors(black);
}

.input {
  width: 100%;
  margin-top: 4rem;
}

.button {
  height: 4.8rem;
  margin-top: 4rem;
  line-height: 4.8rem;

  @include mq() {
    margin-top: 4rem;
  }
}
