@import '../../colors';
@import '../../responsive';

.wrapper {
  margin-left: -4rem;

  @include mq() {
    margin-left: 0;
  }
}

.stationContainer {
  margin-top: 4rem;
  margin-left: 4rem;

  @include mq() {
    margin-left: 0;
  }

  .stationGroup {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4rem;

    @include mq() {
      margin-left: 0;
    }
  }
}

.closedStationContainer {
  margin-top: 4rem;
  margin-left: 4rem;

  @include mq() {
    margin-left: 0;
  }

  .closedStationGroup {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4rem;

    @include mq() {
      margin-left: 0;
    }
  }
}

.stationCreateButton {
  width: auto;
  height: 3.2rem;
  margin-left: 4rem;
  line-height: 3.2rem;

  @include mq() {
    margin-left: 0;
  }

  span {
    font-size: 1.4rem;
  }
}
